import common from "../../api/common";
import axios from "axios";
import Cookies from "js-cookie";
import axiosApiInstance from "../auth/authService";

export const TrackPage = (url) => {
    window.friendbuyAPI = window.friendbuyAPI || []
    window.friendbuyAPI.push([
        "track",
        "page",
        {
            name: url,
        }
    ]);
}
export const TrackPurchase = (data) => {
    window.friendbuyAPI = window.friendbuyAPI || []
    window.friendbuyAPI.push([
        "track",
        "purchase",
        data,
    ]);
}
export const TrackCustomer = (data) => {
    window.friendbuyAPI = window.friendbuyAPI || []
    window.friendbuyAPI.push([
        "track",
        "customer",
        data,
    ]);


}
export const TrackSignUp = (data) => {
    window.friendbuyAPI = window.friendbuyAPI || []
    window.friendbuyAPI.push([
        "track",
        "sign_up",
        data,
    ]);
}
export const TrackEmailCapture = (data) => {
    window.friendbuyAPI = window.friendbuyAPI || []
    window.friendbuyAPI.push([
        "track",
        "email_capture",
        data,
    ]);
}


export const friendBuyAddCustomer = async (data) => {
    var config = {
      method: "post",
      url: `${common.api_url}/friendbuy-referrals-add-customer`,
      data: JSON.stringify(data),
    };
  
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  };
export const friendBuyGetCustomer = async (data) => {
    var config = {
      method: "post",
      url: `${common.api_url}/friendbuy-referrals-get-customer`,
      data: JSON.stringify(data),
    };
  
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  };
export const friendBuyUpdateCustomer = async (data) => {
    var config = {
      method: "post",
      url: `${common.api_url}/friendbuy-referrals-update-customer`,
      data: JSON.stringify(data),
    };
  
    const resp = await axiosApiInstance(config);
    if (resp && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  };